<template>
    <b-container fluid>
        <iq-card>
            <template v-slot:body>
                <b-row>
                    <b-col xs="12" sm="12" md="6" lg="6">
                        <b-form-group
                        class="row"
                        label-cols-sm="3"
                        label-cols-md="4"
                        label-cols-lg="3"
                        :label="$t('warehouse_config.category_name')"
                        label-for="email"
                        >
                        <v-select name="title"
                        v-model="search.category_name"
                        label="text"
                        :options= categoryLists
                        />
                        </b-form-group>
                    </b-col>
                    <b-col xs="12" sm="12" md="6" lg="6">
                        <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
                    </b-col>
                </b-row>
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{$t('warehouse_config.category_list')}}</h4>
                    </template>
                    <template v-slot:headerAction1>

                    </template>
                    <template v-slot:headerAction>
                        <b-button class="btn_add_new" v-b-modal.modal-4 @click="resetId">
                             <i class="fas fa-plus"></i>{{  $t('globalTrans.add_new') }}
                        </b-button>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table thead-class="table_head" striped bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                                        <template v-slot:cell(index)="data">
                                        {{ $n(data.index + pagination.slOffset) }}
                                        </template>
                                        <template v-slot:cell(commodity_group_name)="data">
                                        <span class="capitalize">{{ data.item.commodity_group_name }}</span>
                                        </template>
                                        <template v-slot:cell(stock_duration)="data">
                                        {{ $n(data.item.stock_duration, { useGrouping: false }) }}
                                        </template>
                                        <template v-slot:cell(status)="data">
                                        <!-- {{ data.item.status ? $t('globalTrans.inactive') : $t('globalTrans.active') }} -->
                                            <b-badge variant="danger" v-if="data.item.status == 1">{{$t('globalTrans.inactive')}}</b-badge>
                                        <b-badge variant="success" v-else>{{$t('globalTrans.active')}}</b-badge>
                                        </template>
                                        <template v-slot:cell(action)="data">
                                            <!-- <b-button v-b-modal.modal-4 variant=" iq-bg-success mr-1 mb-1" size="sm" @click="edit(data.item)"><i class="ri-ball-pen-fill m-0"></i></b-button> -->
                                            <!-- <b-button variant=" iq-bg-danger" size="sm" @click="remove(data.item)"><i class="ri-delete-bin-line m-0"></i></b-button> -->
                                            <a href="javascript:" class="btn_table_action table_action_edit" title="Edit" v-b-modal.modal-4 size="sm" @click="edit(data.item)">
                                            <i class="ri-ball-pen-fill"></i>
                                            </a>
                                            <a href="javascript:" class="btn_table_action table_action_toggle" title="Status" size="sm" @click="remove(data.item)" v-if="data.item.status === 1">
                                                <i class="fas fa-toggle-off"></i>
                                            </a>
                                            <a href="javascript:" class="btn_table_action table_action_status" title="Status" size="sm" @click="remove(data.item)" v-else>
                                                <i class="fa fa-toggle-on"></i>
                                            </a>
                                        </template>
                                    </b-table>
                                    <b-pagination
                                        align="center"
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total-rows="pagination.totalRows"
                                        @input="searchData"
                                        />
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
        <b-modal id="modal-4" size="lg" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Form :id="editItemId" :key="editItemId"/>
        </b-modal>

    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import Form from './Form'
import RestApi, { warehouseServiceBaseUrl } from '../../../../../config/api_config'
import { categoriesList, categoriesToggleStatus, categoriesListAll } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'

export default {
    mixins: [ModalBaseMasterList],
    components: {
        Form
    },
    data () {
        return {
            search: {
                category_name: ''
            },
            categoryLists: []
        }
    },
    computed: {
        formTitle () {
            return (this.editItemId === 0) ? this.$t('warehouse_config.category_entry') : this.$t('warehouse_config.category_modify')
        },
        columns () {
            const labels = [
                { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
                { label: this.$t('warehouse_config.category_name'), class: 'text-center' },
                { label: this.$t('globalTrans.status'), class: 'text-center' },
                { label: this.$t('globalTrans.action'), class: 'text-center' }
            ]

            /*eslint-disable */
            let keys = []

            if (this.$i18n.locale === 'bn') {
                keys = [
                { key: 'index' },
                { key: 'category_name_bn' },
                { key: 'status' },
                { key: 'action' }
                ]
            } else {
                keys = [
                { key: 'index' },
                { key: 'category_name' },
                { key: 'status' },
                { key: 'action' }
                ]
            }

            return labels.map((item, index) => {
                return Object.assign(item, keys[index])
            })
        }
    },
    async created () {
        this.loadData()
        this.getCategoryList()
    },
    mounted () {
        core.index()
    },
    methods: {
        dataChange () {
            this.loadData()
        },
        async searchData () {
            this.loadData()
            this.getCategoryList()
        },
        remove (item) {
        this.changeStatus(warehouseServiceBaseUrl, categoriesToggleStatus, item)
        },
        loadData () {
            var category = ''
            if (this.search.category_name) {
                category = this.search.category_name.value
            }
            const params = Object.assign({}, this.search, { category_name: category, page: this.pagination.currentPage, per_page: this.pagination.perPage })
            this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
            RestApi.getData(warehouseServiceBaseUrl, categoriesList, params).then(response => {
                if (response.success) {
                    this.$store.dispatch('setList', response.data.data)
                    this.paginationData(response.data)
                }
                this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
            })
        },
        getCategoryList () {
            RestApi.getData(warehouseServiceBaseUrl, categoriesListAll).then(response => {
                if (response.success) {
                this.categoryLists = response.data.map(obj => {
                    if (this.$i18n.locale === 'bn') {
                    return { value: obj.category_name_bn, text: obj.category_name_bn}
                    } else {
                    return { value: obj.category_name, text: obj.category_name }
                    }
                })
                }
            })
        }
    }
}
</script>
